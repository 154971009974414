<template>
  <div class="page-wrap">
    <div
      id="slide-page"
      :class="[isSideBarOpen && 'slide-wrap', 'dashboard-cnt']"
    >
      <header class="in-head">
        <div class="top-head">
          <div class="head-lft">
            <span
              v-if="!isSideBarOpen && this.userInfo && this.userInfo.name"
              class="icon-btn"
              @click="isSideBarOpen = !isSideBarOpen"
            >
              <MenuIcon />
            </span>

            <span
              v-if="isSideBarOpen && this.userInfo && this.userInfo.name"
              class="icon-btn close-menu"
              @click="isSideBarOpen = !isSideBarOpen"
            >
              <CloseMenuIcon />
            </span>

            <span class="partner-logo">
              <PartnerLogoIcon />
            </span>
          </div>
          <div class="head-rgt">
            <!-- <router-link to="/earning-history" custom v-slot="{ navigate }">
            <span @click="navigate" class="icon-btn" @keypress.enter="navigate"
              ><earing-icon
            /></span>
          </router-link>
          <router-link to="/notifications" custom v-slot="{ navigate }">
            <span @click="navigate" class="icon-btn" @keypress.enter="navigate"
              ><bell-icon
            /></span>
          </router-link> -->
            <!-- <router-link
              to="/user-profile"
              custom
              v-slot="{ navigate }"
              v-if="this.userInfo && this.userInfo.name"
            >
              <span
                @click="navigate"
                class="icon-btn"
                @keypress.enter="navigate"
              >
                <avatar-icon />
              </span>
            </router-link> -->

            <!-- <span class="icon-btn share-btn" @click="shareTask91()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path
                  d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92-1.31-2.92-2.92-2.92z"
                />
              </svg>
            </span> -->
          </div>
        </div>

        <div class="task-tab">
          <!-- <span
            @click="handleToggleTab('howToEarn')"
            :class="{ selected_tab: tabStatus === 'howToEarn' }"
            >How to Earn</span
          > -->
          <span
            @click="handleToggleTab('allTask')"
            :class="{ selected_tab: tabStatus === 'allTask' }"
            >All Task</span
          >

          <!-- <span
            @click="handleToggleTab('mallFrenchiseHistory')"
            :class="{ selected_tab: tabStatus === 'mallFrenchiseHistory' }"
            >Mall Franchise History
          </span> -->
        </div>
      </header>

      <section class="mid-cnt">
        <div class="howtoearn" v-if="tabStatus === 'howToEarn'">
          <div class="earn-cntbox">
            <h1>How To Earn</h1>
            <ul class="step-points">
              <li>
                <span class="check-point">
                  <img src="@/assets/images/checked.svg" alt="" />
                </span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                eos corrupti.
              </li>

              <li>
                <span class="check-point">
                  <img src="@/assets/images/checked.svg" alt="" />
                </span>
                A excepturi et quae qui esse vitae autem velit fuga, nulla
                delectus quis accusantium repellat possimus obcaecati, molestiae
                odio.
              </li>

              <li>
                <span class="check-point">
                  <img src="@/assets/images/checked.svg" alt="" />
                </span>
                Nulla delectus quis accusantium repellat possimus obcaecati,
                molestiae odio.
              </li>
            </ul>
          </div>
          <div class="video-box">
            <iframe
              src="https://www.youtube.com/embed/v1Ow1MOPKA8"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <all-task v-if="tabStatus === 'allTask'" />
        <my-task v-if="tabStatus === 'mallFrenchiseHistory'" />

        <!-- <component :is="tabStatus"></component> -->
        <!-- <router-view /> -->
      </section>
    </div>
    <loader-wrapper v-if="isLoading" />
    <Sidebar :isSlideBarOpen="isSideBarOpen" :userInfo="userInfo" />
  </div>
</template>

<script>
import MenuIcon from "@/components/svgIcons/MenuIcon";
// import EaringIcon from "@/components/svgIcons/EaringIcon";
// import BellIcon from "@/components/svgIcons/BellIcon";
import AvatarIcon from "@/components/svgIcons/AvatarIcon";
import allTask from "@/components/task/AllTasks";
import myTask from "@/components/task/MyTasks";
import PartnerLogoIcon from "@/components/svgIcons/PartnerLogoIcon";
import CloseMenuIcon from "@/components/svgIcons/CloseMenuIcon";
import ApiRequest from "../services/ApiRequest";
import { BASE_API } from "../utils/constants";
import { GET } from "../utils/HttpStatusCodeConst";
import { mapMutations } from "vuex";
import Swal from "sweetalert2";
import LoaderWrapper from "../components/commonUI/LoaderWrapper.vue";

export default {
  name: "Dashboard",
  components: {
    MenuIcon,
    // EaringIcon,
    // BellIcon,
    AvatarIcon,
    allTask,
    myTask,
    PartnerLogoIcon,
    CloseMenuIcon,
    Sidebar: () => import("@/components/sidebar/Sidebar"),
    LoaderWrapper,
  },
  data() {
    return {
      tabStatus: "allTask",
      isSideBarOpen: false,
      isLoading: false,
      userInfo: null,
    };
  },

  methods: {
    shareTask91() {
      try {
        JSBridgePlugin.showToast(
          "Complete tasks on Task91 and earn money. Also get Earning from 15 Level Network. Complete the task now \n\n https://m91.co/Cal-Ky",
          null,
          false
        );
      } catch (e) {
        console.log("js brdge plugin not found");
      }
    },
    handleToggleTab(_item) {
      this.tabStatus = _item;
    },

    ...mapMutations({
      setPartnerTasks: "setPartnerTasks",
      setPartnerStatus: "setPartnerStatus",
      setUserInfo: "setUserInfo",
    }),
    getUserInfo() {
      this.isLoading = true;
      ApiRequest(BASE_API, `/get/partner91`, GET)
        .then((response) => {
          this.setUserInfo(response);
          this.userInfo = response;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          Swal.fire({ text: this.$t("_pleaseTryAgain"), icon: "error" });
        });
    },
    getPartnerTasks() {
      this.isLoading = true;
      ApiRequest(BASE_API, "/partner91/dashboard", GET)
        .then((response) => {
          const { payment, status } = response;
          this.setPartnerTasks(response.data);
          this.setPartnerStatus({ payment, status });
          this.isLoading = false;
          //redirect on partner validation
        })
        .catch((error) => {
          console.log("error:", error);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getPartnerTasks();
    this.getUserInfo();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/dashboard.scss";
</style>
