<template>
  <div class="mytask-listing">
    <loader-wrapper v-if="isLoading" />
    <header class="in-head">
      <div class="top-head">
        <span class="icon-btn" @click="$router.go(-1)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="16"
            viewBox="0 0 18 16"
          >
            <path
              d="M7.993 14.119c.395.432.4 1.127.007 1.558-.39.429-1.019.434-1.418-.004L.297 8.779c-.393-.43-.4-1.122 0-1.56L6.582.326C6.975-.105 7.607-.11 8 .32c.39.428.392 1.121-.007 1.559L3.414 6.902h13.584C17.55 6.902 18 7.39 18 8c0 .606-.452 1.097-1.002 1.097H3.414l4.58 5.023z"
            />
          </svg>
        </span>
        <h1>My Tasks History</h1>
      </div>
    </header>
    <div class="task-filter">
      <div class="lft-item">
        <span class="txt-counter">{{ filteredList.length }}</span>
        <span class="txt-title">Tasks</span>
      </div>

      <div class="rgt-item">
        <div class="filter-tab" @click="tabFilterStatus = !tabFilterStatus">
          <img
            src="../../assets/images/ic_filter.svg"
            alt=""
            class="filter-icon"
          />
          <span>Filter</span>
        </div>
      </div>

      <div
        class="filter-overlay"
        v-if="tabFilterStatus"
        @click="tabFilterStatus = !tabFilterStatus"
      ></div>
      <div class="fliter-list" v-if="tabFilterStatus">
        <ul>
          <li
            v-for="(item, i) in filterOptions"
            :key="i"
            @click="getFilterOption(item)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>

    <ul>
      <li
        style="text-align: center"
        v-if="filteredList && filteredList.length == 0"
      >
        No item found
      </li>
      <li v-else v-for="item in filteredList" :key="item.id">
        <div class="task-infobox task-panding">
          <div class="lft-item">
            <h2 v-if="item.name">
              {{ item.name.charAt(0).toUpperCase() + item.name.slice(1) }}
            </h2>
            <div class="time-status">{{ item.date.split(".")[0] }}</div>
            <div class="time-status">{{ item.mobile || "" }}</div>

            <div class="task-status">
              Status :
              <span
                :style="
                  item.status == 'done'
                    ? 'color: green'
                    : item.status == 'created'
                    ? 'color: blue'
                    : item.status == 'pending'
                    ? 'color: yellow'
                    : item.status == 'rejected'
                    ? 'color: red'
                    : 'color: #fa6400;'
                "
                >{{
                  item.status.charAt(0).toUpperCase() + item.status.slice(1)
                }}</span
              >
            </div>
          </div>
          <div class="rgt-item">
            <div class="task-amt">
              <span class="amt-icon">₹</span>
              <span class="text-amt">{{ item.amount }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import ApiRequest from "@/services/ApiRequest";
import { BASE_API } from "@/utils/constants";
// import { GET } from "@/utils/HttpStatusCodeConst";
import Axios from "axios";
import { SessionHelper, X_AUTH_ID } from "../../services/Session";
import LoaderWrapper from "../commonUI/LoaderWrapper.vue";
// import BackIcon from "../svgIcons/BackIcon.vue";
export default {
  components: {
    LoaderWrapper,
    // BackIcon,
  },
  data() {
    return {
      tabFilterStatus: false,
      isLoading: false,
      myTasksList: [],
      filterOptions: [
        "All",
        "Done",
        "Pending",
        "Rejected",
        "Courier91",
        "Bills91",
        "Lead Generate",
        "QR Code",
      ],
      selectFilter: "",
    };
  },
  computed: {
    ...mapGetters({
      getPartnerTasks: "getPartnerTasks",
    }),
    filteredList() {
      return this.myTasksList && this.myTasksList.length > 0
        ? this.myTasksList.filter((item) => {
            return item.status
              .toLowerCase()
              .includes(this.selectFilter.toLowerCase());
          })
        : this.myTasksList;
    },
  },
  created() {
    this.getMyTasks();
  },
  methods: {
    getFilterOption(item) {
      this.selectFilter = item !== "All" ? item : "";
    },

    async getMyTasks() {
      this.isLoading = true;
      try {
        const { data } = await Axios.get(BASE_API + "/partner91/task", {
          headers: {
            "X-Auth-Id": SessionHelper.getCookie(X_AUTH_ID),
          },
        });
        if (data.status) {
          this.myTasksList = data.data;
        }
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
      // ApiRequest(BASE_API, `/partner91/task`, GET)
      //   .then((response) => {
      //     console.log("response : ", response);
      //     if(response.status){
      //       this.myTasksList = response.data;
      //     }
      //     this.isLoading = false;
      //   })
      //   .catch((error) => {
      //     console.log("error:", error);
      //     this.isLoading = false;
      //   });
    },
  },
};
</script>

<style></style>
