<template>
  <div class="ctg-tasklisting">
    <loader-wrapper v-if="isLoading" />
    <!--<h2></h2>-->
    <ul v-if="getPartnerTasks">
      <li
        class="franchise-ctg"
        :key="task.id"
        v-for="task in getPartnerTasks.filter((n) => n.image)"
        @click="openUrl(task)"
      >
        <!-- @click="openUrl(task.name, task.id, task)" -->
        <div class="inner-task">
          <div class="ctg-iconbox">
            <img :src="task.image" alt="" />
          </div>
          <div class="ctg-info">
            <h2>{{ task.name }}</h2>
            <!-- <p v-html="task.description"></p> -->
          </div>
          <div class="info-btn">
            <span class="text-link"
              >Click here
              <RightSmallArrowIcon />
            </span>
          </div>
        </div>
      </li>
      <!-- <li
        @click="$router.push('/return91')"
      >
        <div class="ctg-iconbox">
          <img :src="computeTaskClass('Return91').imgUrl" alt="" />
        </div>
        <div class="ctg-info">
          <h2>Return91</h2>
          <p>Lorem ipsum dolor, sit amet.</p>
          <span class="text-link">क्लिक करे </span>
        </div>
      </li> -->
    </ul>
  </div>
</template>

<script>
import { SessionHelper } from "@/services/Session";
import { mapGetters } from "vuex";
import LoaderWrapper from "../commonUI/LoaderWrapper.vue";
import Axios from "axios";
import { BASE_API } from "../../utils/constants";
import { X_AUTH_ID } from "../../services/Session";
import RightSmallArrowIcon from "@/components/svgIcons/RightSmallArrowIcon";
export default {
  components: { LoaderWrapper, RightSmallArrowIcon },
  name: "AllTasks",
  data() {
    return {
      tasksList: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getPartnerTasks: "getPartnerTasks",
      getUserInfo: "getUserInfo",
    }),
  },
  methods: {
    // return class name depending on the task name

    async registerForC91() {
      try {
        const { data } = await Axios.put(
          BASE_API + "/partner91/task/update",
          { task_id: 1, is_registered: true },
          {
            headers: {
              "X-Auth-Id": SessionHelper.getCookie(X_AUTH_ID),
            },
          }
        );
        this.isLoading = false;
        if (data && data.result && data.result.status) {
          window.location.href = `https://c91.services-money91.com/#/user/${SessionHelper.getCookie(
            "X-Auth-Id"
          )}`;
        } else {
          this.$swal({
            icon: "error",
            text: `कुछ गलत हुआ, कृपया कुछ समय बाद प्रयास करें।`,
          });
        }
      } catch (e) {
        this.isLoading = false;
        this.$swal({
          icon: "error",
          text: `कुछ गलत हुआ, कृपया कुछ समय बाद प्रयास करें।`,
        });
      }
    },
    async registerForTask(id, path) {
      try {
        const { data } = await Axios.put(
          BASE_API + "/partner91/task/update",
          { task_id: id, is_registered: true },
          {
            headers: {
              "X-Auth-Id": SessionHelper.getCookie(X_AUTH_ID),
            },
          }
        );
        this.isLoading = false;
        if (data && data.result && data.result.status) {
          this.$router.push(path); //'/return91'
        } else {
          this.$swal({
            icon: "error",
            text: `कुछ गलत हुआ, कृपया कुछ समय बाद प्रयास करें।`,
          });
        }
      } catch (e) {
        this.isLoading = false;
        this.$swal({
          icon: "error",
          text: `कुछ गलत हुआ, कृपया कुछ समय बाद प्रयास करें।`,
        });
      }
    },
    openUrl(task) {
      this.$store.commit("setTaskDetails", task);
      this.$router.push("/task-detail");
    },
  },
};
</script>

<style>
.hide {
  display: none !important;
}
</style>
